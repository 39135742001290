<template>
  <li class='border-b border-opacity-50 relative'
    @mouseover='oepnEditMessage'
    @mouseleave='hideEditMessage'>
    <div v-if='showEditMessageHover && !isGbccOnsitePublicSession'
      class='absolute top-0 left-0 w-full h-full z-10 flex flex-row items-center justify-center uppercase cursor-pointer font-semibold bg-gray-50 opacity-75'
      @click='openQuestionEditInput(question.questionText)'>
      Edit
    </div>
    <div v-if='showQuestionTextInput'
      class='absolute top-0 left-0 bg-white w-full h-full z-10 flex flex-row justify-between items-center text-xs'>
      <input
        ref='questionInput'
        class='flex-grow ml-2 my-4 px-2 py-2 border border-gray-200 rounded-l-md text-left'
        v-model='editingQuestionText'
        @keyup.enter='completeQuestionEdit'>
      <div class='py-4 mr-2'>
        <button class='border border-gray-900 bg-gray-900 hover:shadow-md text-white px-4 py-2 rounded-r-md'
          @click='completeQuestionEdit'>
          Save
        </button>
      </div>
      <div class='py-4 flex flex-col mr-2'>
        <button class='border border-transparent hover:border-gray-400 p-1 rounded-md'
          @click='hideQuestionTextInput'>
          Cancel
        </button>
        <button class='border border-transparent hover:border-gray-400 p-1 rounded-md'
          @click='userDeleteQuestion'>
          Delete
        </button>
      </div>
    </div> 
    <div class='px-2 pt-4'>
      {{ question.questionText }}
    </div>
    <div class='px-2 pb-4 flex flex-row items-center justify-between'>
      <div v-if='isGbccOnsitePublicSession'></div>
      <button v-else class='font-thin'>
        <thumb-up-icon class='inline-block'
          :size='selectedIconSize'
          :class='sessionQuestionLikeButtonClass(question.me.liked)'
          @click='postSessionQuestionLike(question.id)'/>
        ({{question.likeCount}})
      </button>
      <div class='font-thin text-gray-900'>
        <span class='inline-block mr-1 tracking-wider'>({{ questionUserNameString }})</span>
        <span class='inline-block'>{{ sessionQuestionSubmittedTime(question.createdAt) }}</span>
      </div>
    </div>
  </li>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { ThumbUpIcon } from '@vue-hero-icons/solid'
import dayjs from 'dayjs'

export default {
  name: 'SessionQuestionRow',
  props: [
    'question', 
    'showLargerFontSize'
  ],
  data () {
    return {
      showEditMessage: false,
      showQuestionTextInput: false,
      editingQuestionText: ''
    }
  },
  components: {
    ThumbUpIcon,
  },
  computed: {
    ...mapState('sessionQuestions', [
      'questionTypeShownToUser'
    ]),
    ...mapGetters('events', [
      'showingEventId',
      'showingEventSessionPageConfigurations',
    ]),
    sessionQuestionConfigs () {
      return this.showingEventSessionPageConfigurations && this.showingEventSessionPageConfigurations.sessionQuestionConfigs ? this.showingEventSessionPageConfigurations.sessionQuestionConfigs : {}
    },
    hideQuestionerName () {
      return this.sessionQuestionConfigs.hideQuestionerName
    },
    submitQuetsionParams () {
      return {
        question: {
          question_text: this.editingQuestionText
        }
      }
    },
    selectedIconSize () {
      return this.showLargerFontSize ? '20' : '16'
    },
    showEditMessageHover () {
      return this.question.me.isMyQuestion && this.showEditMessage && !this.showQuestionTextInput
    },
    isGbccOnsitePublicSession () {
      return (this.showingEventId === 31 || this.showingEventId === 39) && this.$route.name === 'PublicSession'
    },
    questionUserNameString () {
      if (this.isGbccOnsitePublicSession) {
        return 'particiapant'
      } else {
        if (this.question.me.isMyQuestion) {
          return 'me'
        } else {
          if (this.hideQuestionerName) {
            return 'particiapant'
          } else {
            return this.question.user.name
          }
        }
      }
    }
  },
  methods: {
    ...mapActions('sessionQuestions', [
      'postSessionQuestionLike',
      'patchSessionQuestion',
      'patchSessionQuestionStatusToUserDeleted'
    ]),
    sessionQuestionSubmittedTime (time) {
      return dayjs(time).format('HH:mm')
    },
    sessionQuestionLikeButtonClass (likeStatus) {
      return likeStatus ? 'text-blue-800' : 'text-blue-100'
    },
    openQuestionEditInput (question) {
      this.showQuestionTextInput = true
      this.editingQuestionText = question
      this.$nextTick(() => {
        this.$refs.questionInput.focus()
      })
    },
    oepnEditMessage () {
      this.showEditMessage = true
    },
    hideEditMessage () {
      this.showEditMessage = false
    },
    hideQuestionTextInput () {
      this.showQuestionTextInput = false
    },
    showSessionQuestsionDefaultRow () {
      this.hideEditMessage()
      this.hideQuestionTextInput()
    },
    completeQuestionEdit () {
      this.$confirm('Save your changes?', {
        confirmButtonText: 'Save',
        cancelButtonText: 'Discard Changes',
        type: 'success'
      }).then(() => {
        this.patchSessionQuestion({questionId: this.question.id, questionParams: this.submitQuetsionParams}).then(() => {
          this.$message({
            type: 'success',
            message: 'Updated'
          })
          this.showSessionQuestsionDefaultRow()
        })
      }).catch(() => {
        this.showSessionQuestsionDefaultRow()
      })
    },
    userDeleteQuestion () {
      this.$confirm('Delete your question?', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Discard Changes',
        type: 'success'
      }).then(() => {
        this.patchSessionQuestionStatusToUserDeleted({questionId: this.question.id}).then(() => {
          this.$message({
            type: 'success',
            message: 'Delete'
          })
          this.showSessionQuestsionDefaultRow()
        })
      }).catch(() => {
        this.showSessionQuestsionDefaultRow()
      })
    },
  },
}
</script>

<style type='scss' scoped>

.question-selector {
  @apply uppercase border-r w-1/2 text-center py-2 text-sm cursor-pointer;
}
.question-selector:hover {
  color: var(--eventMainColor);
  @apply font-bold;
}
.question-selector.is_active {
  @apply border-b-2 font-bold;
  border-bottom-color:  var(--eventMainColor);
  color: var(--eventMainColor);
}
</style>
