<template>
  <div class='bg-white flex flex-col relative border'>
    <div v-if='hasMyQuestions'
         class='border-b flex flex-row'>
      <div class='question-selector'
           v-if='!hideAllQuestionsTab'
           :class="questionTypeSelectorActiveClass('all')"
           @click="updateSessionQuestionTypeShownToUser('all')">
        All ({{ sessionQuestionsCount }})
      </div>
      <div class='question-selector'
           v-if='!isGbccOnsitePublicSession'
           :class="questionTypeSelectorActiveClass('mine')"
           @click="updateSessionQuestionTypeShownToUser('mine')">
        Mine ({{ mySessionQuestionsCount }})
      </div>
    </div>
    <ul v-if='hasShowingSessionQuestions'
        class='overflow-y-scroll flex-grow'
        style='background-color: #FCFCFC;'
        :class='selectedFontSize'
        v-chat-scroll='{smooth: true, always: false}'>
      <session-question-row v-for='question in showingSessionQuestions'
                            :key='`question-${question.id}`'
                            :question='question'
                            :show-larger-font-size='showLargerFontSize'/>
    </ul>
    <div v-else
         class='h-full w-full flex flex-col justify-center items-center text-center text-sm text-gray-500 font-light rounded-t-lg'
         style='background-color: #FCFCFC;'>
      no questions asked
      <button
        @click='focusAskQuestion'
        class='border border-transparent px-3 py-2 hover:border-gray-200 rounded-md uppercase'>
        Leave a comment or question
      </button>
    </div>
    <div class='flex flex-row'
         :class='selectedFontSize'>
      <input
        ref='newQuestion'
        type='text'
        class='w-5/6 lg:w-3/4 p-3 border-t border-r border-gray-200 rounded-bl-md cursor-pointer'
        style='font-size: 1rem;'
        placeholder='Leave a comment or question'
        v-model='newQuestionText'
        @keypress.enter='submitSessionQuestion'/>
      <button v-if='hasNewQuestionText'
              class='border-t border-gray-300 bg-gray-800 text-white flex-grow rounded-br-md flex justify-center items-center'
              @click='submitSessionQuestion'>
        <send-icon class='w-8' size='20'/>
      </button>
      <div v-else class='flex flex-row flex-grow'>
        <select class='border-t px-1 flex-grow uppercase text-xs w-16'
                v-model='sortOrderForUser'>
          <option value='createdAt'>time</option>
          <option v-if='!isGbccOnsitePublicSession' value='likeCount'>liked</option>
        </select>
        <button class='border-t border-l border-gray-200 flex-grow rounded-br-md px-3'
                @click='selectFontSize'>
          <component :is='iconByFontSize' class='w-8' size='20' />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios  from 'axios'
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex'
import {SendIcon, ZoomInIcon, ZoomOutIcon}   from 'vue-tabler-icons'
import SessionQuestionRow                 from '@/components/sessions/SessionQuestionRow.vue'
import {mapFields}                        from 'vuex-map-fields'

const camelcaseKeys = require('camelcase-keys')

export default {
  name: 'SessionQuestion',
  data () {
    return {
      newQuestionText: '',
      showLargerFontSize: false
    }
  },
  components: {
    SendIcon,
    ZoomInIcon,
    ZoomOutIcon,
    SessionQuestionRow
  },
  watch: {
    $route: {
      handler: function (to, from) {
        if (to !== from && this.$route.query.session_id) {
          if (this.isGbccOnsitePublicSession) {
            this.getPublicSessionQuestions(this.$route.query.session_id)
          } else {
            this.getSessionQuestions(this.$route.query.session_id)
          }
          this.unsubscribeFromSessionQuestions()
          this.subscribeToLiveSessionQuestions(this.$route.query.session_id)
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState('sessionQuestions', [
      'questionTypeShownToUser'
    ]),
    ...mapGetters('events', [
      'showingEventId',
      'showingEventSessionPageConfigurations',
    ]),
    ...mapGetters('sessionQuestions', [
      'showingSessionQuestions',
      'showingSessionQuestionsCount',
      'sessionQuestionsCount',
      'mySessionQuestionsCount'
    ]),
    ...mapFields('sessionQuestions', [
      'sortOrderForUser'
    ]),
    hasShowingSessionQuestions () {
      return this.showingSessionQuestionsCount > 0
    },
    hasMyQuestions () {
      return this.mySessionQuestionsCount > 0
    },
    submitQuestionParams () {
      return {
        question: {
          event_id: this.showingEventId,
          session_id: this.$route.query.session_id,
          question_text: this.newQuestionText
        }
      }
    },
    selectedFontSize () {
      return this.showLargerFontSize ? 'text-lg' : 'text-sm'
    },
    hasNewQuestionText () {
      return this.newQuestionText
    },
    sessionQuestionConfigs () {
      return this.showingEventSessionPageConfigurations && this.showingEventSessionPageConfigurations.sessionQuestionConfigs ? this.showingEventSessionPageConfigurations.sessionQuestionConfigs : {}
    },
    hideAllQuestionsTab () {
      return this.sessionQuestionConfigs.hideAllQuestionsTab
    },
    iconByFontSize () {
      return this.showLargerFontSize ? 'zoom-out-icon' : 'zoom-in-icon'
    },
    isGbccOnsitePublicSession () {
      return (this.showingEventId === 31 || this.showingEventId === 39) && window.location.pathname.includes('public-session')
    },
    publicSessionAccessToken () {
      return this.showingEventSessionPageConfigurations && this.showingEventSessionPageConfigurations.publicSessionAccessToken ? this.showingEventSessionPageConfigurations.publicSessionAccessToken : ''
    },
  },
  methods: {
    ...mapActions('sessionQuestions', [
      'getSessionQuestions',
      'subscribeToLiveSessionQuestions',
      'updateSessionQuestionTypeShownToUser',
      'postSessionQuestion',
      'postSessionQuestionLike',
      'unsubscribeFromSessionQuestions',
    ]),
    ...mapMutations('sessionQuestions', [
      'setSessionQuestions',
      'addCreatedQuestionToSessionQuestions',
    ]),
    publicApiBase (publicSessionAccessToken) {
      return axios.create({
        baseURL: `${process.env.VUE_APP_API}/api/v1`,
        headers: {
          Authorization: {
            toString () {
              return publicSessionAccessToken
            }
          },
          'Content-Type': 'application/json; charset=utf-8',
          'Channel': window.location.host.replace('www.','').split('.')[0]
        }
      })
    },
    getPublicSessionQuestions (sessionId) {
      return new Promise(resolve => {
        let params = {
          event_id: this.showingEventId,
          session_id: sessionId
        }
        this.publicApiBase(this.publicSessionAccessToken).get(`/questions`, { params: params }).then(resp => resp.data).then(resp => {
          this.setSessionQuestions(camelcaseKeys(resp.question_list, { deep: true }))
          resolve()
        })
      })
    },
    postPublicSessionQuestion () {
      this.publicApiBase(this.publicSessionAccessToken).post(`/questions/`, this.submitQuestionParams).then(resp => resp.data).then((resp) => {
        this.addCreatedQuestionToSessionQuestions({from: 'api', createdQuestion: camelcaseKeys(resp.question, { deep: true })})
        this.newQuestionText = ''
      })
    },
    focusAskQuestion () {
      this.$refs.newQuestion.focus()
    },
    questionTypeSelectorActiveClass (type) {
      return (this.questionTypeShownToUser === type) ? 'is_active' : ''
    },
    submitSessionQuestion () {
      if (this.isGbccOnsitePublicSession) {
        this.postPublicSessionQuestion()
      } else {
        this.postSessionQuestion(this.submitQuestionParams).then(() => {
          this.newQuestionText = ''
        })
      }
    },
    selectFontSize () {
      this.showLargerFontSize = !this.showLargerFontSize
    }
  },
  mounted () {
    if (this.hideAllQuestionsTab) {
      this.updateSessionQuestionTypeShownToUser('mine')
    }
  }
}
</script>

<style type='scss' scoped>

.question-selector {
  @apply uppercase text-left px-6 py-3 text-sm cursor-pointer;
}

.question-selector:hover {
  color: var(--eventMainColor);
  @apply font-semibold;
}

.question-selector.is_active {
  @apply border-b-2 font-semibold;
  border-bottom-color: var(--eventMainColor);
  color: var(--eventMainColor);
}
</style>
