<template>
  <div>
    <div class='py-2 lg:py-8 relative'>
      <content-background-cover
        background-color='#F3F4F6'
        class='z-0'
        :top-offset='0' />
      <h1 class='text-lg lg:text-2xl font-semibold mb-4'>
        {{ currentSession.title }}
        <span v-if='currentSession.description'> - {{ currentSession.description }}</span>
      </h1>
      <div v-if='hasManySessionListOfContents' 
        class='text-right flex flex-row items-center pb-2 px-2 lg:px-0'>
        <div class='flex flex-row items-center justify-end gap-x-2 mr-4'>
          <label v-for='(contentOption, index) in currentSession.listOfContents'
            :key='`content-list-select-${contentOption.id}`'
            class='flex flex-row justify-start items-center gap-x-1 text-sm text-gray-500 border border-blue-300 py-1 px-4 shadow-md lg:shadow-lg rounded'>
            <input type='radio'
                   :value='index'
                   v-model='sessionCurrentContentIndex'
                   class=''>
            <span>{{contentOption.data.contentLabel}}</span>
          </label>
        </div>
      </div>
      <div ref='sessionContentContainer'
           class='flex flex-col lg:flex-row lg:gap-x-4 lg:items-stretch lg:justify-start relative'>
        <div
          :key='pageId'
          class='flex-shrink-0 z-10 relative flex flex-row items-center justify-center bg-gray-900'>
          <div id='vimeo-player'></div>
        </div>
        <session-question v-if='!isFullscreenVideo'
          class='lg:flex-grow mt-2 lg:mt-0 z-10 lg:rounded-lg'
          :style='`height: ${sessionContentContainerHeight}px`' />
      </div>
    </div>
    <div class='pb-64'>
      <div class='mt-2 mx-8 lg:px-0 px-4'>
        <vue-slick-carousel v-if='currentSession.carouselSponsors.length' v-bind='settings'>
          <div v-for='(sponsor, index) in currentSession.carouselSponsors' :key='`sponsor-${index}`'>
            <div class='h-24 flex flex-col justify-center items-center'>
              <img 
                :src='sponsor.logoUrl' 
                class='p-4 block mx-auto max-h-24' 
                style='width: auto; max-width: 200px;'
                :class='hasLinkCssClass(sponsor.siteUrl)'
                @click='clickImage(sponsor.siteUrl)'>
            </div>
          </div>
        </vue-slick-carousel>
      </div>
      <session-details-full
        class='mt-4 px-2 lg:px-0'
        :session='currentSession' />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations }       from 'vuex'
import SessionQuestion        from '@/components/sessions/SessionQuestion.vue'
import ContentBackgroundCover from '@/components/ContentBackgroundCover.vue'
import SessionDetailsFull     from '@/components/sessions/SessionDetailsFull.vue'
import Player                 from '@vimeo/player'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import dayjs from 'dayjs'

const camelcaseKeys = require('camelcase-keys')

export default {
  name: 'PublicSession',
  components: {
    SessionQuestion,
    ContentBackgroundCover,
    VueSlickCarousel,
    SessionDetailsFull,
  },
  data () {
    return {
      currentTimeUpdater: null,
      currentTime: dayjs(new Date()).format(),
      pageId: '',
      sessionContentContainerWidth: 0,
      liveVideoPlayer: null,
      sessions: [],
      sessionDefault: {
        listOfContents: [
          {
            id: 0
          }
        ],
        locations: [],
        configurations: {},
        carouselSponsors: [],
        speakers: []
      },
      sessionCurrentContentIndex: 0,
      settings: {
        'lazyLoad': 'ondemand',
        'dots': false,
        'dotsClass': 'slick-dots custom-dot-class',
        'focusOnSelect': true,
        'infinite': true,
        'speed': 500,
        'autoplay': true,
        'adaptiveHeight': false,
        'autoplaySpeed': 10000,
        'slidesToShow': 6,
        'slidesToScroll': 2,
        'touchThreshold': 6,
        'responsive': [
          {
            'breakpoint': 1024,
            'settings': {
              'slidesToShow': 4,
              'slidesToScroll': 1
            }
          },
          {
            'breakpoint': 600,
            'settings': {
              'slidesToShow': 3,
              'slidesToScroll': 1
            }
          },
          {
            'breakpoint': 480,
            'settings': {
              'slidesToShow': 2,
              'slidesToScroll': 1
            }
          }
        ]
      },
    }
  },
  watch: {
    currentSession: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          let query = Object.assign({}, this.$route.query)
          query.session_id = newVal.id
          this.$router.replace({ query })
        }
      },
      immediate: true,
      deep: true
    },
    contentID: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal != oldVal) {
          this.$nextTick(() => {
            if (this.liveVideoPlayer) {
              this.liveVideoPlayer.destroy()
            }
            this.liveVideoPlayer = new Player('vimeo-player', this.videoOptions)
            this.liveVideoPlayer.ready().then(() => {
              console.log('ready')
              this.resizePlayer()
            })
            this.liveVideoPlayer.on('fullscreenchange', (resp) => {
              this.fullscreenUpdate(resp)
            })
            this.liveVideoPlayer.on('error', (error) => {
              console.error('error', error)
              this.$confirm('Cannot show the live video. Please refresh.', {
                confirmButtonText: 'Refrsh',
                cancelButtonText: 'Cancel',
                type: 'warning'
              }).then(() => {
                this.$router.go()
              }).catch(() => {
                this.$router.go()
              })
            })
          })
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState([
      'isFullscreenVideo',
    ]),
    currentSession () {
      if (this.sessions.length > 0) {
        if (dayjs(this.currentTime) < dayjs(this.sessions[1].scheduledStartTime)) {
          return this.sessions[0]
        } else {
          return this.sessions[1]
        }
      } else {
        return this.sessionDefault
      }
    },
    sessionContentContainerHeight () {
      return this.sessionContentContainerWidth * (9/16) // assuming this is the standard vimeo aspect ratio
    },
    contentID () {
      return this.currentSession.listOfContents[this.sessionCurrentContentIndex].id
    },
    hasManySessionListOfContents () {
      return this.currentSession.listOfContents.length > 1
    },
    videoOptions () {
      return {
        autoplay: true,
        responsive: true,
        title: false,
        byline: false,
        speed: false,
        url: this.currentSession.listOfContents[this.sessionCurrentContentIndex].data.sourceUrl
      }
    },
    showSessionQuestionsBox () {
      return true
    },
  },
  methods: {
    ...mapMutations([
      'setIsFullscreenVideo'
    ]),
    loadApi () {
      if (this.pageId) {
        const subdomain_name = new URL(location.href).host.replace('www.','').split('.')[0]
        let public_api_url = ''
        if (subdomain_name === 'gbcc-onsite-staging') {
          public_api_url = `https://webconcert-staging-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/gbcc-onsite-staging/public/public-api-${this.pageId}.json`
        } else if (subdomain_name === 'gbcc-onsite') {
          public_api_url = `https://d3r2ol85dktaw0.cloudfront.net/conferences/gbcc-onsite/public/public-api-${this.pageId}.json`
        }
        fetch(public_api_url)
          .then(response => {
            if (!response.ok) {
              throw new Error("HTTP error " + response.status);
            }
            return response.json()
          })
          .then((json) => {
            this.sessions = camelcaseKeys(json['sessions'], {deep: true})
          })
      }
    },
    getContainerWidth () {
      if (this.showSessionQuestionsBox && this.$refs.sessionContentContainer.clientWidth >= 1024) {
        this.sessionContentContainerWidth = this.$refs.sessionContentContainer.clientWidth * 0.7
      } else {
        this.sessionContentContainerWidth = this.$refs.sessionContentContainer.clientWidth
      }
      this.resizePlayer()
    },
    resizePlayer () {
      let player = document.querySelector('#vimeo-player iframe')
      if (player) {
        player.width = `${this.sessionContentContainerWidth}px`
        player.height = `${this.sessionContentContainerHeight}px`
      }
    },
    hasLinkCssClass (url) {
      return (url) ? 'cursor-pointer' : ''
    },
    clickImage (url) {
      if (url) {
        if (url.indexOf('http') != -1) {
          window.open(url, '_blank')
        } else {
          window.open(`https://${url}`, '_blank')
        }
      } else {
        // do nothing
      }
    },
    fullscreenUpdate (resp) {
      this.setIsFullscreenVideo(resp.fullscreen)
    },
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getContainerWidth)
    clearInterval(this.currentTimeUpdater)
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.getContainerWidth)
      this.getContainerWidth()
    })
    this.currentTimeUpdater = setInterval(() => {
      this.currentTime = dayjs(new Date()).format()
    }, 180000)
    this.pageId = this.$route.query.page_id
    this.loadApi()
  }
}
</script>

<style lang='scss'>
  .slick-prev:before {
    color: black;
  }

  .slick-next:before {
    color: black;
  }

  .slick-track {
    display: flex !important;
    align-items: center;
  }
</style>